<template>
  <div class="intro">
    <div
      class="modal"
      v-if="showMovie"
      :class="{'is-active' : showMovie}"
    >
      <div
        class="modal-background"
        @click="showHideMovie"
      ></div>
      <div class="modal-content">
        <iframe
          width="100%"
          height="405"
          src="https://www.youtube-nocookie.com/embed/6njXx-a7VBM?rel=0&autoplay=1"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="showHideMovie"
      ></button>
    </div>

    <div class="columns-top columns is-multiline">
      <div class="column is-half-tablet is-one-third-desktop">
        <div class="message-usps message p-4 is-shadowless is-primary">
          <h3 class="title is-3 has-text-centered has-text-blue-dark">What can you do with Your S2M Passport?</h3>
          <table class="table table-usps has-background-transparent">
            <tbody>
              <tr>
                <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
                <td><span class="is-size-5 has-text-weight-bold">Get AI driven opportunities</span></td>
              </tr>
              <tr>
                <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
                <td><span class="is-size-5 has-text-weight-bold">Ask the expert</span></td>
              </tr>
              <tr>
                <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
                <td><span class="is-size-5 has-text-weight-bold">Stay in touch</span></td>
              </tr>
              <tr>
                <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
                <td><span class="is-size-5 has-text-weight-bold">Manage your bookings and data</span></td>
              </tr>
              <tr>
                <td><span class="icon is-medium has-text-primary"><i class="fas fas fa-2x fa-check"></i></span></td>
                <td><span class="is-size-5 has-text-weight-bold">Access the entire S2M Ecosystem</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column">
        <login
          class="login-form-wrapper"
          @isLoggedIn="isLoggedIn"
        ></login>
      </div>
    </div>

    <hr />

    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="padding has-background-white">
          <div class="columns">
            <div class="column is-two-fifths">
              <img
                src="@/assets/img/Get_AI_driven_opportunities_2.png"
                style="cursor: pointer;"
                width="100%"
                alt="Get AI driven opportunities"
                title="Get AI driven opportunities"
                @click="showHideMovie"
              />
            </div>
            <div class="column is-three-fifths">
              <div class="tableElm">
                <div class="tableCellElm">
                  <h3 class="title is-2">GET AI DRIVEN OPPORTUNITIES</h3>
                  <span class="is-size-5">The Serendipity Machine is now part of the S2M passport, that means that through the passport you get matched by an AI driven algorithm to other users, locations, events, content and questions made by the S2M ecosystem.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column column-info is-full">
        <div class="padding has-background-white">
          <div class="columns">
            <div class="column is-three-fifths">
              <div class="tableElm">
                <div class="tableCellElm">
                  <h3 class="title is-2">STAY IN TOUCH</h3>
                  <span class="is-size-5">In the passport besides getting matched you can also stay in touch with them. Chat and request meetings with the users around you and your best matches.</span>
                </div>
              </div>
            </div>
            <div class="column is-two-fifths">
              <img
                src="@/assets/img/Stay_in_touch.png"
                width="100%"
                alt="Stay in touch"
                title="Stay in touch"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column column-info is-full">
        <div class="padding has-background-white">
          <div class="columns">
            <div class="column is-two-fifths">
              <img
                src="@/assets/img/Ask_the_expert.png"
                width="100%"
                alt="Ask the experts"
                title="Ask the experts"
              />
            </div>
            <div class="column is-three-fifths">
              <div class="tableElm">
                <div class="tableCellElm">
                  <h3 class="title is-2">ASK THE EXPERTS</h3>
                  <span class="is-size-5">When asking questions in the S2M ecosystem you also use the Serendipity Machine AI driven algorithm. By choosing tags related to your question we will send it only to experts in that determined subject, so you get relevant answers.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column column-info is-full">
        <div class="padding has-background-white">
          <div class="columns">
            <div class="column is-three-fifths">
              <div class="tableElm">
                <div class="tableCellElm">
                  <h3 class="title is-2">MANAGE YOUR DATA</h3>
                  <span class="is-size-5">Within the S2M passport you can also manage all your data and privacy settings. You can change, add or delete tags, profile pictures, e-mail settings and more, making the passport completely GDPR compliant.</span>
                </div>
              </div>
            </div>
            <div class="column is-two-fifths">
              <img
                src="@/assets/img/Manage_your_data.png"
                width="100%"
                alt="Manage your data"
                title="Manage your data"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column column-info is-full">
        <div class="padding has-background-white">
          <div class="columns">
            <div class="column is-two-fifths">
              <img
                src="@/assets/img/Access_the_entire_S2M_ecosystem.png"
                width="100%"
                alt="Access the entire S2M_ecosystem"
                title="Access the entire S2M_ecosystem"
              />
            </div>
            <div class="column is-three-fifths">
              <div class="tableElm">
                <div class="tableCellElm">
                  <h3 class="title is-2">ACCESS THE ENTIRE S2M ECOSYSTEM</h3>
                  <span class="is-size-5">Through the S2M passport you can access all aspects of the S2M Ecosystem in a single place. Access other users, bookings, events, content, an AI Driven Q&A, privacy settings and you personal data.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column column-info is-full">
        <div class="padding has-background-white">
          <div class="columns">
            <div class="column is-three-fifths">
              <div class="tableElm">
                <div class="tableCellElm">
                  <h3 class="title is-2">MANAGE YOUR BOOKINGS</h3>
                  <span class="is-size-5">In the S2M passport you can create and manage meeting spaces or workspaces bookings in all the 200 locations worldwide.</span>
                </div>
              </div>
            </div>
            <div class="column is-two-fifths">
              <img
                src="@/assets/img/Manage_your_bookings.png"
                width="100%"
                alt="Manage your bookings"
                title="Manage your bookings"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column column-info is-full">
        <div class="message p-4 has-background-primary has-text-centered has-text-white content">
          <h2 class="title is-2 has-text-white">GET STARTED</h2>
          <p class="is-size-5">Create your Seats2meet profile to start using the S2M Passport.</p>
          <router-link
            class="button is-primary is-large"
            :to="{name: 'Register'}"
          >Get your passport</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      s2mUrl: process.env.VUE_APP_S2M_BASE_URL,
      showMovie: false
    }
  },

  created() {
    window.scrollTo(0, 0)
  },

  methods: {
    isLoggedIn() {
      let callbackData = this.$store.getters.getLoginCallback
      this.$store.commit('setLoginCallback', null)

      if (callbackData !== null && typeof callbackData !== 'undefined' && callbackData.name !== 'ResetPassword') {
        this.$router.push({ name: callbackData.name, params: callbackData.params })
      } else {
        this.$router.push({ name: `Dashboard` })
      }
    },
    showHideMovie() {
      this.showMovie = !this.showMovie
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-usps {
  height: 100%;
  .table-usps {
    td {
      padding: 5px 0;
      border: none;
      &:first-child {
        padding-right: $gap;
      }
    }
  }
}

.tableElm {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;

  .tableCellElm {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
  }
}

@media screen and (min-width: $desktop) {
  .login-form-wrapper {
    margin: 0 auto;
    width: 70%;
  }
}

@media screen and (max-width: $tablet) {
  .columns-top {
    display: flex;
    flex-flow: column;

    .column:nth-child(1) {
      order: 2;
    }
  }
  .column-info {
    .columns {
      display: flex;
      flex-flow: column;

      .is-two-fifths {
        order: 1;
      }
      .is-three-fifths {
        order: 2;
      }
    }
  }
}
</style>
