var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro"},[(_vm.showMovie)?_c('div',{staticClass:"modal",class:{'is-active' : _vm.showMovie}},[_c('div',{staticClass:"modal-background",on:{"click":_vm.showHideMovie}}),_vm._m(0),_c('button',{staticClass:"modal-close is-large",attrs:{"aria-label":"close"},on:{"click":_vm.showHideMovie}})]):_vm._e(),_c('div',{staticClass:"columns-top columns is-multiline"},[_vm._m(1),_c('div',{staticClass:"column"},[_c('login',{staticClass:"login-form-wrapper",on:{"isLoggedIn":_vm.isLoggedIn}})],1)]),_c('hr'),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"padding has-background-white"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-two-fifths"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/img/Get_AI_driven_opportunities_2.png"),"width":"100%","alt":"Get AI driven opportunities","title":"Get AI driven opportunities"},on:{"click":_vm.showHideMovie}})]),_vm._m(2)])])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"column column-info is-full"},[_c('div',{staticClass:"message p-4 has-background-primary has-text-centered has-text-white content"},[_c('h2',{staticClass:"title is-2 has-text-white"},[_vm._v("GET STARTED")]),_c('p',{staticClass:"is-size-5"},[_vm._v("Create your Seats2meet profile to start using the S2M Passport.")]),_c('router-link',{staticClass:"button is-primary is-large",attrs:{"to":{name: 'Register'}}},[_vm._v("Get your passport")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('iframe',{attrs:{"width":"100%","height":"405","src":"https://www.youtube-nocookie.com/embed/6njXx-a7VBM?rel=0&autoplay=1","frameborder":"0","allow":"autoplay; encrypted-media","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-half-tablet is-one-third-desktop"},[_c('div',{staticClass:"message-usps message p-4 is-shadowless is-primary"},[_c('h3',{staticClass:"title is-3 has-text-centered has-text-blue-dark"},[_vm._v("What can you do with Your S2M Passport?")]),_c('table',{staticClass:"table table-usps has-background-transparent"},[_c('tbody',[_c('tr',[_c('td',[_c('span',{staticClass:"icon is-medium has-text-primary"},[_c('i',{staticClass:"fas fas fa-2x fa-check"})])]),_c('td',[_c('span',{staticClass:"is-size-5 has-text-weight-bold"},[_vm._v("Get AI driven opportunities")])])]),_c('tr',[_c('td',[_c('span',{staticClass:"icon is-medium has-text-primary"},[_c('i',{staticClass:"fas fas fa-2x fa-check"})])]),_c('td',[_c('span',{staticClass:"is-size-5 has-text-weight-bold"},[_vm._v("Ask the expert")])])]),_c('tr',[_c('td',[_c('span',{staticClass:"icon is-medium has-text-primary"},[_c('i',{staticClass:"fas fas fa-2x fa-check"})])]),_c('td',[_c('span',{staticClass:"is-size-5 has-text-weight-bold"},[_vm._v("Stay in touch")])])]),_c('tr',[_c('td',[_c('span',{staticClass:"icon is-medium has-text-primary"},[_c('i',{staticClass:"fas fas fa-2x fa-check"})])]),_c('td',[_c('span',{staticClass:"is-size-5 has-text-weight-bold"},[_vm._v("Manage your bookings and data")])])]),_c('tr',[_c('td',[_c('span',{staticClass:"icon is-medium has-text-primary"},[_c('i',{staticClass:"fas fas fa-2x fa-check"})])]),_c('td',[_c('span',{staticClass:"is-size-5 has-text-weight-bold"},[_vm._v("Access the entire S2M Ecosystem")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-three-fifths"},[_c('div',{staticClass:"tableElm"},[_c('div',{staticClass:"tableCellElm"},[_c('h3',{staticClass:"title is-2"},[_vm._v("GET AI DRIVEN OPPORTUNITIES")]),_c('span',{staticClass:"is-size-5"},[_vm._v("The Serendipity Machine is now part of the S2M passport, that means that through the passport you get matched by an AI driven algorithm to other users, locations, events, content and questions made by the S2M ecosystem.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column column-info is-full"},[_c('div',{staticClass:"padding has-background-white"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-three-fifths"},[_c('div',{staticClass:"tableElm"},[_c('div',{staticClass:"tableCellElm"},[_c('h3',{staticClass:"title is-2"},[_vm._v("STAY IN TOUCH")]),_c('span',{staticClass:"is-size-5"},[_vm._v("In the passport besides getting matched you can also stay in touch with them. Chat and request meetings with the users around you and your best matches.")])])])]),_c('div',{staticClass:"column is-two-fifths"},[_c('img',{attrs:{"src":require("@/assets/img/Stay_in_touch.png"),"width":"100%","alt":"Stay in touch","title":"Stay in touch"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column column-info is-full"},[_c('div',{staticClass:"padding has-background-white"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-two-fifths"},[_c('img',{attrs:{"src":require("@/assets/img/Ask_the_expert.png"),"width":"100%","alt":"Ask the experts","title":"Ask the experts"}})]),_c('div',{staticClass:"column is-three-fifths"},[_c('div',{staticClass:"tableElm"},[_c('div',{staticClass:"tableCellElm"},[_c('h3',{staticClass:"title is-2"},[_vm._v("ASK THE EXPERTS")]),_c('span',{staticClass:"is-size-5"},[_vm._v("When asking questions in the S2M ecosystem you also use the Serendipity Machine AI driven algorithm. By choosing tags related to your question we will send it only to experts in that determined subject, so you get relevant answers.")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column column-info is-full"},[_c('div',{staticClass:"padding has-background-white"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-three-fifths"},[_c('div',{staticClass:"tableElm"},[_c('div',{staticClass:"tableCellElm"},[_c('h3',{staticClass:"title is-2"},[_vm._v("MANAGE YOUR DATA")]),_c('span',{staticClass:"is-size-5"},[_vm._v("Within the S2M passport you can also manage all your data and privacy settings. You can change, add or delete tags, profile pictures, e-mail settings and more, making the passport completely GDPR compliant.")])])])]),_c('div',{staticClass:"column is-two-fifths"},[_c('img',{attrs:{"src":require("@/assets/img/Manage_your_data.png"),"width":"100%","alt":"Manage your data","title":"Manage your data"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column column-info is-full"},[_c('div',{staticClass:"padding has-background-white"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-two-fifths"},[_c('img',{attrs:{"src":require("@/assets/img/Access_the_entire_S2M_ecosystem.png"),"width":"100%","alt":"Access the entire S2M_ecosystem","title":"Access the entire S2M_ecosystem"}})]),_c('div',{staticClass:"column is-three-fifths"},[_c('div',{staticClass:"tableElm"},[_c('div',{staticClass:"tableCellElm"},[_c('h3',{staticClass:"title is-2"},[_vm._v("ACCESS THE ENTIRE S2M ECOSYSTEM")]),_c('span',{staticClass:"is-size-5"},[_vm._v("Through the S2M passport you can access all aspects of the S2M Ecosystem in a single place. Access other users, bookings, events, content, an AI Driven Q&A, privacy settings and you personal data.")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column column-info is-full"},[_c('div',{staticClass:"padding has-background-white"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-three-fifths"},[_c('div',{staticClass:"tableElm"},[_c('div',{staticClass:"tableCellElm"},[_c('h3',{staticClass:"title is-2"},[_vm._v("MANAGE YOUR BOOKINGS")]),_c('span',{staticClass:"is-size-5"},[_vm._v("In the S2M passport you can create and manage meeting spaces or workspaces bookings in all the 200 locations worldwide.")])])])]),_c('div',{staticClass:"column is-two-fifths"},[_c('img',{attrs:{"src":require("@/assets/img/Manage_your_bookings.png"),"width":"100%","alt":"Manage your bookings","title":"Manage your bookings"}})])])])])
}]

export { render, staticRenderFns }